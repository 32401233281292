export const CustomerPermissions = {
    CustomerRead: 1,
    CustomerWrite: 2,
    CustomerEmailWrite: 77,
} as const;

export const VehiclePermissions = {
    VehicleWrite: 3,
    VehicleRead: 4,
    VehicleHistoryWrite: 27,
    VehicleHistoryRead: 28,
    VehiclePredictionWrite: 29,
    VehiclePredictionRead: 30,
    AgeGroupRead: 38,
    AgeGroupWrite: 39,
    InvoiceSalesTypeMapRead: 40,
    InvoiceSalesTypeMapWrite: 41,
    VehicleHistoryDescriptionMapRead: 48,
    VehicleHistoryDescriptionMapWrite: 49,
    InvoiceDepartmentMapRead: 54,
    InvoiceDepartmentMapWrite: 55,
    PartCategoryMapRead: 56,
    PartCategoryMapWrite: 57,
} as const;

export const RetailerPermissions = {
    RetailerRead: 5,
    RetailerWrite: 6,
    RetailerSiteRead: 7,
    RetailerSiteWrite: 8,
    RetailerGroupRead: 9,
    RetailerGroupWrite: 10,
    RetailerCodeRead: 11,
    RetailerCodeWrite: 12,
    MarketRead: 13,
    MarketWrite: 14,
    RegionRead: 15,
    RegionWrite: 16,
    AreaRead: 17,
    AreaWrite: 18,
    BrandRead: 19,
    BrandWrite: 20,
    StaffMemberWrite: 21,
    StaffMemberRead: 22,
    RetailerPostcodeAreaRead: 52,
    RetailerPostcodeAreaWrite: 53,
} as const;

export const SettingPermissions = {
    SettingRead: 23,
    SettingWrite: 24,
} as const;

export const ConsentTypePermissions = {
    ConsentTypeRead: 25,
    ConsentTypeWrite: 26,
} as const;

export const ImportPermissions = {
    ImportWrite: 31,
    ImportCustomer: 601,
    ImportLabour: 602,
    ImportPart: 603,
    ImportRetailer: 604,
    ImportServiceHistory: 605,
    ImportVehicle: 606,
    ImportMarket: 607,
    ImportNews: 608,
    ImportOrder: 609,
    ImportReward: 610,
    ImportSales: 611,
    ImportAfterSalesTransaction: 612,
    ImportVehicleOwnership: 613,
} as const;

export const OrderPermissions = {
    OrderRead: 32,
    OrderWrite: 33,
} as const;

export const NewsPermissions = {
    NewsRead: 34,
    NewsWrite: 35,
} as const;

export const InterestsPermissions = {
    InterestsRead: 36,
    InterestsWrite: 37,
} as const;

export const PromotionPermissions = {
    PromotionRead: 42,
    PromotionWrite: 43,
} as const;

export const NavigationPromptPermissions = {
    NavigationPromptRead: 44,
    NavigationPromptWrite: 45,
} as const;

export const RewardPermissions = {
    RewardRead: 46,
    RewardWrite: 47,
} as const;

export const QuestionPermissions = {
    QuestionRead: 50,
    QuestionWrite: 51,
    QuestionnaireResponseWrite: 58,
} as const;

export const GraphFacebookPermissions = {
    GraphFacebookRead: 59,
    GraphFacebookWrite: 60,
} as const;

export const ReportingPermissions = {
    CustomerMemberCountRead: 61,
    ReportingInputsRead: 200,
    AftersalesKpiReportRead: 201,
    ServiceRetentionReportRead: 202,
    PartsPerformanceReportRead: 203,
    DataQualityReportRead: 204,
    OpportunityReportRead: 205,
    WorkshopPerformanceReportRead: 206,
    VehicleRevenueReportRead: 207,
    MissedRevenueReportRead: 208,
    InvoiceGroupsReportRead: 209,
    RetentionInvoiceReportRead: 210,
    WorkshopInsightsReportRead: 211,
    WorkshopPartsSalesTypeRead: 212,
    WorkshopModelsRead: 213,
    WorkshopLabourRead: 214,
    TradepartsSalesReportRead: 215,
    PartsInsightsReportRead: 216,
    NonTradepartsSalesReportRead: 217,
    PartsSalesTypeAnalysisReportRead: 218,
    WorkshopPartsSalesRead: 219,
    PartsVehicleAgeAnalysisRead: 220,
    MonthlyDataQualityReportRead: 221,
    DataQualityKpiRead: 222,
    CampaignSummaryRead: 223,
    CampaignKpiRead: 224,
    DealerLeagueRead: 225,
    CampaignRevenueRead: 226,
    ServiceHistoryReportRead: 227,
    RetentionTrendRead: 228,
    RetentionVisitDeviation: 229,
    MembersReportRead: 230,
    InviteAuditReportRead: 241,
    SalesLeadReportRead: 243,
    SalesReport: 248,
} as const;

export const NonFranchiseRetailerPermissions = {
    NonFranchiseRetailerRead: 62,
    NonFranchiseRetailerWrite: 62,
    NonFranchiesRetailerSiteRead: 63,
    NonFranchiesRetailerSiteWrite: 64,
} as const;

export const VehicleModelPermissions = {
    VehicleModelRead: 65,
} as const;

export const InvoicePermissions = {
    InvoiceRead: 66,
    InvoiceWrite: 67,
} as const;

export const AfterSalesManagementPermissions = {
    AfterSalesManagement: 68,
} as const;

export const RoadsideAssistancePermissions = {
    RoadsideAssistance: 69,
} as const;

export const OnlineServiceBookingPermissions = {
    OnlineServiceBookingRead: 70,
    OnlineServiceBookingWrite: 71,
} as const;

export const LoyaltyApiPermissions = {
    LoyaltyAPIsRead: 72,
    LoyaltyAPIsWrite: 73,
} as const;

export const ExternalUrlsPermissions = {
    ExternalUrlRead: 74,
    ExternalUrlWrite: 75,
} as const;

export const ForgottenPasswordPermissions = {
    ForgottenPasswordRead: 76,
} as const;

export const SalesLeadPermissions = {
    SalesLeadManagement: 78,
} as const;

export const IdentityPermissions = {
    UserRead: 100,
    UserWrite: 101,
    RoleRead: 102,
    RoleWrite: 103,
    ForgottenPasswordEmailRead: 104,
    ForgottenPasswordEmailWrite: 105,
    CreateServiceUserAccount: 106,
    GenerateRandomPassword: 107,
    MigrateStaffUsers: 802,
} as const;

export const ReportingCSVExtractPermissions = {
    ExtractCampaignURLsReport: 231,
    ExtractCampaignSummariesReport: 232,
    ExtractDataQualityReport: 233,
    ExtractInvoiceGroupsReport: 234,
    ExtractMembersReport: 235,
    ExtractNonTradepartsSalesReport: 236,
    ExtractServiceRetentionReport: 237,
    ExtractTradepartsSalesReport: 238,
    ExtractWorkshopLabourReport: 239,
    ExtractWorkshopPartsSalesReport: 240,
    ExtractInviteAuditReport: 242,
    ExtractSalesLeadsReport: 244,
} as const;

export const MarketingPermissions = {
    TemplateRead: 500,
    TemplateWrite: 501,
    TagRead: 502,
    TagWrite: 503,
    MergeFieldRead: 504,
    MergeFieldWrite: 505,
    CategoryRead: 506,
    CategoryWrite: 507,
    CampaignRead: 508,
    CampaignWrite: 509,
    TargetRead: 510,
    TargetWrite: 511,
    MergeUrlRead: 512,
    MergeUrlWrite: 513,
    SendTestCampaign: 514,
    TargetByFilterSetRequestModelRead: 515,
    ManualCampaignSend: 516,
    TargetByFilterSetCsvRead: 517,
    AllowedRecipientRead: 518,
    AllowedRecipientWrite: 519,
    SuppressionListRead: 520,
    SuppressionListWrite: 521,
    MilestoneRead: 522,
    MilestoneWrite: 523,
    NotificationSettingRead: 524,
    NotificationSettingWrite: 525,
    RestrictedDateRead: 526,
    RestrictedDateWrite: 527,
} as const;

export const VinDecoderPermissions = {
    VinDecoderVehicleDetailRead: 700,
    VinDecoderManagement: 701,
} as const;

export const ExtractPermissions = {
    ExtractCustomers: 801,
    ExtractHandoverQuestionResponses: 804,
    ExtractAfterSalesLeads: 805,
    ExtractExpectedBooking: 806,
} as const;

export const OnlineServiceBookingExternalPermissions = {
    OnlineServiceBooking: 803,
} as const;

export const RightFirstTimePermissions = {
    RightFirstTimeByDealer: 247,
    RightFirstTimeByVehicle: 249,
} as const;

export const UIFeaturesPermissions = {
    SmsCampaigns: 9996,
    ManageContent: 9997,
    Localisation: 9998,
    HideUIFeature: 9999,
    CustomerSearchById: 10000,
} as const;

export const Lead = {
    NonMemberLeadManagement: 92
}

export const OpenRepairs = {
    OpenRepairOrder: 250
}

export const Permissions = {
    ...CustomerPermissions,
    ...VehiclePermissions,
    ...RetailerPermissions,
    ...SettingPermissions,
    ...ConsentTypePermissions,
    ...ImportPermissions,
    ...OrderPermissions,
    ...NewsPermissions,
    ...InterestsPermissions,
    ...PromotionPermissions,
    ...NavigationPromptPermissions,
    ...RewardPermissions,
    ...QuestionPermissions,
    ...GraphFacebookPermissions,
    ...ReportingPermissions,
    ...NonFranchiseRetailerPermissions,
    ...VehicleModelPermissions,
    ...InvoicePermissions,
    ...AfterSalesManagementPermissions,
    ...RoadsideAssistancePermissions,
    ...OnlineServiceBookingPermissions,
    ...LoyaltyApiPermissions,
    ...ExternalUrlsPermissions,
    ...ForgottenPasswordPermissions,
    ...SalesLeadPermissions,
    ...IdentityPermissions,
    ...ReportingCSVExtractPermissions,
    ...MarketingPermissions,
    ...VinDecoderPermissions,
    ...ExtractPermissions,
    ...OnlineServiceBookingExternalPermissions,
    ...RightFirstTimePermissions,
    ...UIFeaturesPermissions,
    ...Lead,
    ...OpenRepairs
} as const;
