import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import ChainedBackend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";

import format from "i18n-format";

import moment from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import localeData from "dayjs/plugin/localeData";
import domainLocaleMap from "./domainLocaleMap.json";

import "dayjs/locale/nb";
import "dayjs/locale/da";
import "dayjs/locale/sv";

moment.extend(localizedFormat);
moment.extend(localeData);
const getLocale = () =>
    typeof window !== "undefined" ? domainLocaleMap[window.location.host] : "en";

// Create an array of the available locales in the system
const locales = new Map([
    ["en", "en"],
    ["da", "da"],
    ["no", "no"],
    ["sv", "sv"],
]);

const fetchTranslation = (options, url, payload, callback) =>
    fetch(url, {
        headers: {
            "Cache-Control": "no-store",
            Pragma: "no-cache",
            Expires: 0,
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, { status: 200, data }))
        .catch((error) => callback(error, { status: 500 }));

// Set the language of dayjs to the current language
moment.locale(locales.get(getLocale()));

function genRandomNumber(length) {
    const chars = "0123456789";
    const charLength = chars.length;
    let result = "";
    for (let i = 0; i < length; i += 1) {
        result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
}

const HASH = genRandomNumber(10);
i18n.use(ChainedBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: false,
        defaultLocale: getLocale() || "en",
        // debug: process.env.NODE_ENV === "development",
        load: "languageOnly",
        ns: ["common", "usermanagement"],
        lng: getLocale() || "en",
        locales: [...locales.keys()],
        detection: {
            order: ["domain"],
        },
        domains: Object.keys(JSON.parse(JSON.stringify(domainLocaleMap))),
        preload: [getLocale()],
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
            format,
        },
        backend: {
            backends:
                process.env.NODE_ENV === "development"
                    ? [HttpBackend]
                    : [LocalStorageBackend, HttpBackend],
            backendOptions:
                process.env.NODE_ENV === "development"
                    ? [
                          {
                              allowMultiLoading: true,

                              loadPath:
                                  "https://platformcms.blob.core.windows.net/translation-files/{{lng}}/{{ns}}.json",
                              request: fetchTranslation,
                          },
                      ]
                    : [
                          {
                              enabled: "true",
                              prefix: "translation_files_",
                              expirationTime: 24 * 60 * 60 * 1000 * 7, // 7 days
                              defaultVersion: `v${HASH}`, // generate a new version every build to refresh
                              store: typeof window !== "undefined" ? window.localStorage : null,
                          },
                          {
                              allowMultiLoading: true,
                              loadPath:
                                  "https://platformcms.blob.core.windows.net/translation-files/{{lng}}/{{ns}}.json",
                              request: fetchTranslation,
                          },
                      ],
            allowMultiLoading: true,
        },
        react: {
            wait: true,
            bindI18n: "languageChanged loaded",
            bindStore: "added removed",
            nsMode: "default",
        },
    });
export default i18n;
