import { Axios as AxiosInstance } from "reactxios";
import {
    AfterSalesApi,
    AfterSalesCampaignApi,
    AreaApi,
    BrandApi,
    CampaignApi,
    ConsentTypeApi,
    CustomerApi,
    CustomerRetailerApi,
    CustomerVehicleRelationshipApi,
    EntitiesApi,
    InterestApi,
    InvoiceDepartmentApi,
    InvoiceSalesTypeApi,
    MarketApi,
    MarketingApi,
    NavigationPromptApi,
    NewsApi,
    NonFranchiseRetailerApi,
    NonMemberPrintApi,
    OrdersApi,
    PartCategoryApi,
    PermissionApi,
    PromotionalLeadApi,
    QuestionnaireApi,
    QuestionnaireResponseApi,
    RegionApi,
    ReportingApi,
    ReportingInputsApi,
    ReportingOpenRepairOrderApi,
    ReportingOpenRepairOrderNotesApi,
    RestrictedDateApi,
    RetailerApi,
    RetailerCodeApi,
    RetailerGroupApi,
    RetailerSiteApi,
    RewardApi,
    RoleApi,
    SalesLeadApi,
    SendWindowApi,
    SenderProfileApi,
    SettingApi,
    StaffMemberApi,
    UserApi,
    VehicleAgeGroupingApi,
    VehicleApi,
    VehicleHistoryDescriptionsApi,
    VehicleModelApi,
    VehicleRecallsApi,
    VinDecoderApi,
} from "@loyaltylogistix/api-client";

const params = [undefined, "", AxiosInstance as any] as const;

export const AreaClient = new AreaApi(...params);
export const BrandClient = new BrandApi(...params);
export const CampaignClient = new CampaignApi(...params);
export const SenderProfileClient = new SenderProfileApi(...params);
export const ConsentTypeClient = new ConsentTypeApi(...params);
export const CustomerClient = new CustomerApi(...params);
export const CustomerVehicleRelationshipClient = new CustomerVehicleRelationshipApi(...params);
export const EntitiesClient = new EntitiesApi(...params);
export const MarketClient = new MarketApi(...params);
export const MarketingClient = new MarketingApi(...params);
export const NewsClient = new NewsApi(...params);
export const NonFranchiseRetailerApiClient = new NonFranchiseRetailerApi(...params);
export const NonMemberPrintClient = new NonMemberPrintApi(...params);
export const OrdersClient = new OrdersApi(...params);
export const PermissionClient = new PermissionApi(...params);
export const PromotionalLeadClient = new PromotionalLeadApi(...params);
export const RegionClient = new RegionApi(...params);
export const RewardsClient = new RewardApi(...params);
export const ReportingClient = new ReportingApi(...params);
export const RetailerClient = new RetailerApi(...params);
export const RetailerCodeClient = new RetailerCodeApi(...params);
export const RetailerGroupClient = new RetailerGroupApi(...params);
export const RetailerSiteClient = new RetailerSiteApi(...params);
export const RoleClient = new RoleApi(...params);
export const SalesLeadClient = new SalesLeadApi(...params);
export const SettingClient = new SettingApi(...params);
export const StaffMemberClient = new StaffMemberApi(...params);
export const UserClient = new UserApi(...params);
export const VehicleClient = new VehicleApi(...params);
export const VehiclesAgeGroupClient = new VehicleAgeGroupingApi(...params);
export const ReportingInputsClient = new ReportingInputsApi(...params);
export const ReportingOpenRepairOrderClient = new ReportingOpenRepairOrderApi(...params);
export const ReportingORONotesClient = new ReportingOpenRepairOrderNotesApi(...params);
export const InterestsClient = new InterestApi(...params);
export const NavigationPromptClient = new NavigationPromptApi(...params);
export const InvoiceDepartmentClient = new InvoiceDepartmentApi(...params);
export const InvoiceSalesTypeClient = new InvoiceSalesTypeApi(...params);
export const PartCategoryClient = new PartCategoryApi(...params);
export const VehicleHistoryDescriptionsClient = new VehicleHistoryDescriptionsApi(...params);
export const VehicleModelClient = new VehicleModelApi(...params);
export const AfterSalesClient = new AfterSalesApi(...params);
export const QuestionnaireClient = new QuestionnaireApi(...params);
export const QuestionnaireResponseApiClient = new QuestionnaireResponseApi(...params);
export const VinDecoderClient = new VinDecoderApi(...params);
export const RestrictedDateClient = new RestrictedDateApi(...params);
export const SendWindowClient = new SendWindowApi(...params);
export const AfterSalesCampaignClient = new AfterSalesCampaignApi(...params);
export const CustomerRetailerClient = new CustomerRetailerApi(...params);

export const VehicleRecallsClient = new VehicleRecallsApi(...params);
