import React from "react";
import {
    faBullhorn,
    faCar,
    faHandshake,
    faSearch,
    faSignal,
    faWrench,
} from "@fortawesome/pro-regular-svg-icons";
import { useRecoilValue } from "recoil";
import { PlatformUserPermissions } from "state/global";
import { Permissions } from "global/permissions";

/** ------------------------- Communications ------------------------- */

const Journeys = React.lazy(() => import("Containers/Communications/Journeys/Journeys"));

const Templates = React.lazy(() => import("Containers/Communications/Templates/index"));

/** ------------------------- Reporting ------------------------- */

const PartsPerformance = React.lazy(() =>
    import("Containers/Reporting/PartsPerformance/PartsPerformance")
);

const Retention = React.lazy(() => import("Containers/Reporting/Retention/Retention"));

const Summary = React.lazy(() => import("Containers/Reporting/Summary/Summary"));

const DataQuality = React.lazy(() => import("Containers/Reporting/DataQuality/DataQuality"));

const WorkshopPerformance = React.lazy(() =>
    import("Containers/Reporting/WorkshopPerformance/WorkshopPerformance")
);

const Parts = React.lazy(() => import("Containers/Reporting/Parts/Parts"));

const Campaigns = React.lazy(() => import("Containers/Reporting/Campaigns/Campaigns"));

const Members = React.lazy(() => import("Containers/Reporting/Members/Members"));

const DealerTable = React.lazy(() => import("Containers/Reporting/DealerTable"));

const SalesReporting = React.lazy(() => import("Containers/Reporting/Sales"));

/** ------------------------- Settings ------------------------- */

const UserManagement = React.lazy(() =>
    import("Containers/Settings/Usermanagement/Usermanagement")
);

const Localization = React.lazy(() => import("Containers/Settings/Localization/Localization"));

const NetworkManagement = React.lazy(() => import("Containers/Settings/NetworkManagement"));

const ContentManagement = React.lazy(() => import("Containers/Settings/ContentManagement"));

const RolesManagement = React.lazy(() => import("Containers/Settings/Roles"));

const VehicleSettings = React.lazy(() => import("Containers/Settings/Vehicles"));

const Publications = React.lazy(() => import("Containers/Settings/Publications"));
const Reports = React.lazy(() => import("Containers/Settings/Reports"));

const CustomerSettings = React.lazy(() => import("Containers/Settings/Customer"));

const CampaignSettings = React.lazy(() => import("Containers/Settings/Campaigns"));

/** ------------------------- Search ------------------------- */

const GeneralSearch = React.lazy(() => import("Containers/GeneralSearch/GeneralSearch"));

/** ------------------------- Customers ------------------------- */

const CustomerProfile = React.lazy(() =>
    import("Containers/Customers/CustomerProfile/CustomerProfile")
);

/** ------------------------- Vehicles ------------------------- */

const VehicleProfile = React.lazy(() =>
    import("Containers/Vehicles/VehicleProfile/VehicleProfile")
);

const VehicleOrders = React.lazy(() => import("Containers/VehicleOrders/VehicleOrders"));

/** ------------------------- Digital Handover ------------------------- */

const DigitalHandover = React.lazy(() => import("Containers/DigitalHandover"));
const Handover = React.lazy(() => import("Containers/Handover"));

/** ------------------------- Sales Leads --------------------------- */

const ScheduledSalesLeads = React.lazy(() => import("Containers/SalesLeads/Pages/Scheduled"));
const ManualSalesLeads = React.lazy(() => import("Containers/SalesLeads/Pages/Manual"));

/** ------------------------ Service Leads -------------------------- */

const FollowUpServiceLeads = React.lazy(() => import("Containers/ServiceLeads/Pages/FollowUp"));
const PlannedServiceLeads = React.lazy(() => import("Containers/ServiceLeads/Pages/Planned"));
const ActionedServiceLeads = React.lazy(() => import("Containers/ServiceLeads/Pages/Actioned"));
const RecallEventsServiceLeads = React.lazy(() =>
    import("Containers/ServiceLeads/Pages/RecallEvents")
);
const RightFirstTime = React.lazy(() => import("Containers/ServiceLeads/Pages/RightFirstTime"));
const RepairOrderTracking = React.lazy(() =>
    import("Containers/ServiceLeads/Pages/RepairOrderTracking")
);
const Promotions = React.lazy(() => import("Containers/ServiceLeads/Pages/Promotions"));

const DealerOperationsReport = React.lazy(() =>
    import("Containers/ServiceLeads/Pages/DealerOperationsReport")
);

export function useRoutes() {
    const permissions = useRecoilValue(PlatformUserPermissions);

    // Create an array for the routes across the platform
    const ROUTES = React.useMemo(
        () => ({
            SETTINGS: {
                isNav: false,
                navigation: {
                    USERMANAGEMENT: {
                        template: "page-template",
                        title: "Manage Users",
                        path: "/settings/usermanagement",
                        get scoopedPermissions() {
                            return [101, 62, 63, 64];
                        },
                        component: <UserManagement />,
                    },
                    LOCALIZATION: {
                        template: "page-template",
                        title: "Localization",
                        path: "/settings/localization",
                        get scoopedPermissions() {
                            return [23, 24];
                        },
                        component: <Localization />,
                    },
                    NETWORKMANAGEMENT: {
                        template: "page-template",
                        title: "Manage Network",
                        path: "/settings/network",
                        get scoopedPermissions() {
                            return [23, 24];
                        },
                        component: <NetworkManagement />,
                    },
                    CONTENTMANAGEMENT: {
                        template: "page-template",
                        title: "Manage Content",
                        path: "/settings/contentManagement",
                        get scoopedPermissions() {
                            return [23, 24];
                        },
                        component: <ContentManagement />,
                    },
                    ROLESMANAGEMENT: {
                        template: "page-template",
                        title: "Manage Roles",
                        path: "/settings/roles",
                        get scoopedPermissions() {
                            return 103;
                        },
                        component: <RolesManagement />,
                    },
                    VEHICLESETTINGS: {
                        template: "page-template",
                        title: "Settings Vehicle",
                        path: "/settings/vehicles",
                        get scoopedPermissions() {
                            return [
                                Permissions.VinDecoderManagement,
                                Permissions.VehicleHistoryDescriptionMapRead,
                            ];
                        },
                        component: <VehicleSettings />,
                    },
                    PUBLICATIONS: {
                        template: "page-template",
                        title: "Publications",
                        path: "/settings/publications",
                        get scoopedPermissions() {
                            return [
                                Permissions.HideUIFeature,
                                // Permissions.SettingRead,
                                // Permissions.NavigationPromptRead,
                                // Permissions.RewardRead
                            ];
                        },
                        component: <Publications />,
                    },
                    REPORTS: {
                        template: "page-template",
                        title: "Reports",
                        path: "/settings/reports",
                        get scoopedPermissions() {
                            return [
                                Permissions.HideUIFeature,
                                // Permissions.AgeGroupRead,
                                // Permissions.PartCategoryMapRead,
                                // Permissions.InvoiceSalesTypeMapRead,
                                // Permissions.InvoiceDepartmentMapRead
                            ];
                        },
                        component: <Reports />,
                    },
                    CUSTOMER_SETTINGS: {
                        template: "page-template",
                        title: "Customer Settings",
                        path: "/settings/customer",
                        get scoopedPermissions() {
                            return [
                                Permissions.HideUIFeature,
                                // Permissions.SettingRead,
                                // Permissions.ConsentTypeRead,
                                // Permissions.InterestsRead,
                                // Permissions.SuppressionListRead,
                            ];
                        },
                        component: <CustomerSettings />,
                    },
                    CAMPAIGN_SETTINGS: {
                        template: "page-template",
                        title: "Campaign Settings",
                        path: "/settings/campaign",
                        get scoopedPermissions() {
                            return [
                                Permissions.HideUIFeature,
                                Permissions.RestrictedDateRead,
                                Permissions.CampaignRead,
                            ];
                        },
                        component: <CampaignSettings />,
                    },
                },
                get path() {
                    const routes = Object.values(this.navigation);
                    const firstPermissableRoute = routes.find((route) =>
                        permissions.some((permission) =>
                            Array.isArray(route.scoopedPermissions)
                                ? route.scoopedPermissions.includes(permission.id)
                                : permission.id === route.scoopedPermissions
                        )
                    );
                    return firstPermissableRoute?.path;
                },
                get scoopedPermissions() {
                    return [
                        ...new Set(
                            Object.values(this.navigation)
                                .map((route) => route.scoopedPermissions)
                                .flat()
                        ),
                    ];
                },
            },
            SEARCH: {
                order: 0,
                isNav: true,
                exact: true,
                name: "search",
                template: "page-template",
                title: "Search",
                icon: faSearch,
                get path() {
                    return "/search";
                },
                get scoopedPermissions() {
                    return [1, 4];
                },
                component: <GeneralSearch />,
            },
            CONTACT: {
                order: 2,
                isNav: true,
                name: "contact",
                title: "Contact",
                icon: faBullhorn,
                navigation: {
                    COMMUNICATIONS: {
                        template: "page-template",
                        title: "Communications",
                        path: "/contact/communications",
                        get scoopedPermissions() {
                            return [508];
                        },
                        component: <Journeys />,
                    },
                    TEMPLATES: {
                        template: "page-template",
                        title: "Templates",
                        path: "/contact/templates",
                        get scoopedPermissions() {
                            return [501];
                        },
                        component: <Templates />,
                    },
                },
                get path() {
                    const routes = Object.values(this.navigation);
                    const firstPermissableRoute = routes.find((route) =>
                        permissions.some((permission) =>
                            Array.isArray(route.scoopedPermissions)
                                ? route.scoopedPermissions.includes(permission.id)
                                : permission.id === route.scoopedPermissions
                        )
                    );
                    return firstPermissableRoute?.path;
                },
                get scoopedPermissions() {
                    return [
                        ...new Set(
                            Object.values(this.navigation)
                                .map((route) => route.scoopedPermissions)
                                .flat()
                        ),
                    ];
                },
            },
            REPORTING: {
                order: 1,
                isNav: true,
                name: "reporting",
                title: "Analyse",
                icon: faSignal,
                navigation: {
                    SUMMARY: {
                        template: "page-template",
                        title: "Summary",
                        path: "/reporting/summary",
                        get scoopedPermissions() {
                            return 201;
                        },
                        component: <Summary />,
                    },
                    RETENTION: {
                        template: "page-template",
                        title: "Retention",
                        path: "/reporting/retention",
                        get scoopedPermissions() {
                            return 202;
                        },
                        component: <Retention />,
                    },
                    WORKSHOP_PERFORMANCE: {
                        template: "page-template",
                        title: "Workshop Performance",
                        path: "/reporting/workshop-performance",
                        get scoopedPermissions() {
                            return 206;
                        },
                        component: <WorkshopPerformance />,
                    },
                    PARTS: {
                        template: "page-template",
                        title: "parts-page-title",
                        path: "/reporting/parts",
                        get scoopedPermissions() {
                            return Permissions.HideUIFeature;
                        },
                        component: <Parts />,
                    },
                    PARTS_PERFORMANCE: {
                        template: "page-template",
                        title: "Parts Performance",
                        path: "/reporting/parts-performance",
                        get scoopedPermissions() {
                            return 203;
                        },
                        component: <PartsPerformance />,
                    },
                    DATA_QUALITY: {
                        template: "page-template",
                        title: "Data Quality",
                        path: "/reporting/data-quality",
                        get scoopedPermissions() {
                            return 204;
                        },
                        component: <DataQuality />,
                    },
                    CAMPAIGNS: {
                        template: "page-template",
                        title: "Campaigns",
                        path: "/reporting/campaigns",
                        get scoopedPermissions() {
                            return [223, 224];
                        },
                        component: <Campaigns />,
                    },
                    MEMBERS: {
                        template: "page-template",
                        title: "Members",
                        path: "/reporting/members",
                        get scoopedPermissions() {
                            return 61;
                        },
                        component: <Members />,
                    },
                    DEALER_TABLE: {
                        template: "page-template",
                        title: "Dealer Table",
                        path: "/reporting/dealer-table",
                        get scoopedPermissions() {
                            return 225;
                        },
                        component: <DealerTable />,
                    },
                    SALES: {
                        template: "page-template",
                        title: "Sales",
                        path: "/reporting/sales",
                        get scoopedPermissions() {
                            return Permissions.SalesReport;
                        },
                        component: <SalesReporting />,
                    },
                },
                get path() {
                    const routes = Object.values(this.navigation);
                    const firstPermissableRoute = routes.find((route) =>
                        permissions.some((permission) =>
                            Array.isArray(route.scoopedPermissions)
                                ? route.scoopedPermissions.includes(permission.id)
                                : permission.id === route.scoopedPermissions
                        )
                    );
                    return firstPermissableRoute?.path;
                },
                get scoopedPermissions() {
                    return [
                        ...new Set(
                            Object.values(this.navigation)
                                .map((route) => route.scoopedPermissions)
                                .flat()
                        ),
                    ];
                },
            },
            DIGITAL_HANDOVER: {
                order: 3,
                isNav: true,
                name: "handover",
                template: "page-template",
                title: "Handover",
                icon: faHandshake,
                get path() {
                    return "/digital-handover";
                },
                get scoopedPermissions() {
                    return [3, 33];
                },
                // component: <DigitalHandover />,
                component: <Handover />,
            },
            VEHICLE_ORDERS: {
                order: 4,
                isNav: true,
                template: "page-template",
                title: "Vehicle Orders",
                icon: faCar,
                get path() {
                    return "/vehicle-orders";
                },
                get scoopedPermissions() {
                    return [32, 33];
                },
                component: <VehicleOrders />,
            },
            VEHICLE_PROFILE: {
                isNav: false,
                template: "page-template",
                title: "View Vehicle",
                get path() {
                    return "/search/vehicle/:id";
                },
                get scoopedPermissions() {
                    return 4;
                },
                component: <VehicleProfile />,
            },
            CUSTOMER_PROFILE: {
                isNav: false,
                template: "page-template",
                title: "View Customer",
                get path() {
                    return "/search/customer/:id";
                },
                get scoopedPermissions() {
                    return 1;
                },
                component: <CustomerProfile />,
            },
            SALES_LEADS: {
                isNav: true,
                name: "sales",
                title: "Sales",
                icon: faCar,
                navigation: {
                    SCHEDULED: {
                        template: "page-template",
                        title: "Scheduled",
                        path: "/sales/scheduled",
                        get scoopedPermissions() {
                            return 78;
                        },
                        component: <ScheduledSalesLeads />,
                    },
                    MANUAL: {
                        template: "page-template",
                        title: "Manual",
                        path: "/sales/manual",
                        get scoopedPermissions() {
                            return 78;
                        },
                        component: <ManualSalesLeads />,
                    },
                },
                get path() {
                    const routes = Object.values(this.navigation);
                    const firstPermissableRoute = routes.find((route) =>
                        permissions.some((permission) =>
                            Array.isArray(route.scoopedPermissions)
                                ? route.scoopedPermissions.includes(permission.id)
                                : permission.id === route.scoopedPermissions
                        )
                    );
                    return firstPermissableRoute?.path;
                },
                get scoopedPermissions() {
                    return [
                        ...new Set(
                            Object.values(this.navigation)
                                .map((route) => route.scoopedPermissions)
                                .flat()
                        ),
                    ];
                },
            },
            SERVICE_LEADS: {
                isNav: true,
                name: "service",
                title: "Service",
                icon: faWrench,
                navigation: {
                    FOLLOW_UP: {
                        template: "page-template",
                        title: "Follow Up",
                        path: "/service/follow-up",
                        get scoopedPermissions() {
                            return 68;
                        },
                        component: <FollowUpServiceLeads />,
                    },
                    PLANNED: {
                        template: "page-template",
                        title: "Planned",
                        path: "/service/planned",
                        get scoopedPermissions() {
                            return 68;
                        },
                        component: <PlannedServiceLeads />,
                    },
                    RECALL_EVENTS: {
                        template: "page-template",
                        title: "Recall Events",
                        path: "/service/recall-events",
                        get scoopedPermissions() {
                            return 84;
                        },
                        component: <RecallEventsServiceLeads />,
                    },
                    RIGHT_FIRST_TIME: {
                        template: "page-template",
                        title: "right-first-time",
                        path: "/service/right-first-time",
                        get scoopedPermissions() {
                            return [
                                Permissions.RightFirstTimeByDealer,
                                Permissions.RightFirstTimeByVehicle,
                            ];
                        },
                        component: <RightFirstTime />,
                    },
                    REPAIR_ORDER_TRACKING: {
                        template: "page-template",
                        title: "repair-order-tracking",
                        path: "/service/repair-order-tracking",
                        get scoopedPermissions() {
                            return Permissions.OpenRepairOrder;
                        },
                        component: <RepairOrderTracking />,
                    },
                    DEALER_OPERATIONS_REPORT: {
                        template: "page-template",
                        title: "dealer-operations-report-title",
                        path: "/service/dealer-operations-report",
                        get scoopedPermissions() {
                            return Permissions.HideUIFeature;
                        },
                        component: <DealerOperationsReport />,
                    },
                    PROMOTIONS: {
                        template: "page-template",
                        title: "promotions-title",
                        path: "/service/promotions",
                        get scoopedPermissions() {
                            return Permissions.HideUIFeature;
                        },
                        component: <Promotions />,
                    },
                },
                get path() {
                    const routes = Object.values(this.navigation);
                    const firstPermissableRoute = routes.find((route) =>
                        permissions.some((permission) =>
                            Array.isArray(route.scoopedPermissions)
                                ? route.scoopedPermissions.includes(permission.id)
                                : permission.id === route.scoopedPermissions
                        )
                    );
                    return firstPermissableRoute?.path;
                },
                get scoopedPermissions() {
                    return [
                        ...new Set(
                            Object.values(this.navigation)
                                .map((route) => route.scoopedPermissions)
                                .flat()
                        ),
                    ];
                },
            },
        }),
        [permissions]
    );
    // Create a function that extracts the side nav items from the ROUTES
    const getSideNavItems = () =>
        Object.entries(ROUTES).flatMap(([_, route]) => {
            if (
                route.isNav &&
                permissions.some((permission) => route.scoopedPermissions.includes(permission.id))
            ) {
                return route;
            }
            return [];
        });
    // Fetch all components to be displayed across the platform
    const getComponents = () =>
        Object.entries(ROUTES).flatMap(([_, route]) => {
            if (route.component) {
                return route;
            }
            if (route.navigation) {
                return Object.values(route.navigation).map((item) => ({
                    ...item,
                    navigation: route.navigation,
                }));
            }
            return [];
        });
    const COMPONENTS = getComponents();
    const SIDENAVITEMS = getSideNavItems().sort((routeA, routeB) => routeA.order - routeB.order);
    return [COMPONENTS, ROUTES, SIDENAVITEMS];
}
