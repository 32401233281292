import { useAccount, useMsal } from "@azure/msal-react";
import { redirectUri } from "state/global/core/utils/msalFactory";
import { UserModel } from "@loyaltylogistix/api-client";
import { useQuery } from "react-query";

import { UserClient } from "services/api.clients";

export default function useActiveAccount(onSuccess?: (user: UserModel) => void) {
    const { instance } = useMsal();
    const account = useAccount();
    const userId = account?.idTokenClaims?.extension_UserId as string;

    const { status, data, error, isLoading } = useQuery(
        ["activeUser", userId],
        () => UserClient.identityUsersIdGet(userId).then((res) => res.data),
        {
            enabled: !!userId,
            onSuccess,
            onError: (err) => {
                instance.logoutRedirect({
                    account: null,
                    postLogoutRedirectUri: redirectUri,
                });
                // Clear the storage to remove the token and also the id of
                // the user's account
                localStorage.clear();
            },
        }
    );

    return {
        status,
        error,
        user: data,
        isLoading,
    };
}
