import { ValidationError } from "joi";
import { GeneralColors, Theme } from "@loyaltylogistix/component-library/theme/future/interfaces";
import { rankItem } from "@tanstack/match-sorter-utils";
import { CellContext, FilterFn, HeaderContext } from "@tanstack/react-table";
import { toast } from "Components/ToastContainer";
import { History } from "history";
import { QueryClient, QueryFunctionContext, QueryKey } from "react-query";
import { PermissionModel } from "@loyaltylogistix/api-client";
import { Dayjs } from "dayjs";
import { TFunction } from "i18next";

export type GMISOptions = {
    prime?: number;
    low?: number;
    high?: number;
};

export interface QueryParametersProps {
    key: string;
    value: string;
    history: History;
    search: any;
    pathname: string;
}

export const generateModulatedIntFromString = (
    value: string,
    { prime = 120328326, low = 0, high = 360 }: GMISOptions = {}
) => {
    let base = parseInt(value, 36) % prime;

    if (Number.isNaN(base)) base = 10;

    while (base < low || base > high) {
        base = base > high ? base - high : base + base;
    }

    return base;
};

export const getGeneralColorFromString = (value: string, theme: Theme) => {
    const generalColorKeys = Object.keys(theme.palette.general);
    const determinateInt = generateModulatedIntFromString(value, {
        prime: generalColorKeys.length - 1,
        high: generalColorKeys.length - 1,
    });

    const key = generalColorKeys[Math.floor(determinateInt)];
    return theme.palette.general[key as keyof GeneralColors];
};

type ExcludeUndefinedFromUnion<T> = T extends undefined ? never : T;

export const onlyUndefinedFallback = <Arg>(
    arg?: Arg,
    ...args: Arg[]
): ExcludeUndefinedFromUnion<Arg> => {
    if (typeof arg !== "undefined") return arg as ExcludeUndefinedFromUnion<Arg>;
    return onlyUndefinedFallback(...args);
};

// only normalizes rands, but could be extended to normalize multiple currencies :)
export function normalizeCurrencyValue(value?: string | number): string {
    // this regex should work with most ways different currencies are displayed etc...
    // however may not work with countries that use "," to denote decimal places instead of .
    const asNumber = Number(`${value}`.replaceAll(/([^0-9-.])/g, ""));
    const fixed = Number(asNumber.toFixed());
    const asRands = `R ${fixed.toLocaleString()}`;
    return asRands;
}

/**
 * Adds multiple query parameters to the URL
 * @param key: of the query parameter
 * @param value: of the query parameter
 * @param history: A history is an interface to the navigation stack
 * @param search: the query string
 * @param pathname: of the current route
 */
export function addQuery({ key, value, history, search, pathname }: QueryParametersProps) {
    const searchParams = new URLSearchParams(search);
    searchParams.set(key, value);
    history.push({
        pathname,
        search: searchParams.toString(),
    });
}

export const FromAnyToString = (value: any): string => {
    if (
        typeof value === "number" ||
        typeof value === "string" ||
        typeof value === "undefined" ||
        value === null
    )
        return String(value || "");

    if (Array.isArray(value)) return value.map((item) => FromAnyToString(item)).join(", ");

    return Object.values(value)
        .map((item) => FromAnyToString(item))
        .join(", ");
};

export const ArraysAreEqual = (
    array1: any[],
    array2: any[],
    sortFn?: (a: any, b: any) => number
) => {
    if (array1.length !== array2.length) return false;

    const a1Sorted = array1.sort(sortFn);
    const a2Sorted = array2.sort(sortFn);

    return a1Sorted.every((item, index) => item === a2Sorted[index]);
};

export const AccessColumnRecord = <TDataModel, TAccessor extends keyof TDataModel>(
    value: CellContext<TDataModel, string>,
    accessor: TAccessor
) => value.row.original[accessor];

export type Translations<TKeys extends string[]> = {
    [key in TKeys[number]]: string;
};

export const toKebabCase = (str?: string | null) => {
    if (!str) return "";

    let kebabCase = "";
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < str.length; ++i) {
        switch (str[i]) {
            case " ":
            case "_":
            case "-":
                kebabCase += "-";
                // eslint-disable-next-line no-continue
                continue;
            case str[i].toUpperCase():
                kebabCase += `-${str[i].toLowerCase()}`;
                // eslint-disable-next-line no-continue
                continue;
            default:
                kebabCase += str[i];
        }
    }

    if (kebabCase[0] === "-") kebabCase = kebabCase.slice(1);
    return kebabCase;
};

export const toCamelCase = (str: string) =>
    `${str[0].toLowerCase()}${str.slice(1)}`.replace(/[-_](.)/g, (_, c) => c.toUpperCase());

export const fuzzyFilter: FilterFn<any> = (row, columnId, searchValue, addMeta) => {
    const cellValue = row.getValue(columnId);
    const normalizedValue =
        typeof cellValue === "object" ? Object.values(cellValue || {}).join(" ") : cellValue;

    // Rank the item
    const itemRank = rankItem(normalizedValue, searchValue);

    // Store the itemRank info
    addMeta({
        itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
};

export function CreateOptimisticSuccessFunction(key: QueryKey, queryClient: QueryClient) {
    return async () => queryClient.invalidateQueries(key);
}

export function CreateOptimisticRollbackFunction(
    key: QueryKey,
    queryClient: QueryClient,
    showError = false,
    formatError: (err: unknown) => string = () => "Something went wrong"
) {
    return (err: unknown, variables: unknown, previousData: unknown) => {
        if (showError) toast.error(formatError(err));
        queryClient.setQueryData(key, previousData);
    };
}

export function getSharedMutationStatus(...status: ("idle" | "error" | "loading" | "success")[]) {
    if (status.includes("error")) return "error";
    if (status.includes("loading")) return "loading";
    if (status.includes("success")) return "success";
    return "idle";
}

export function toAsyncWrapperState(
    state: "loading" | "error" | "success" | "idle"
): "loading" | "hasValue" | "hasError" {
    switch (state) {
        case "loading":
            return "loading";
        case "error":
            return "hasError";
        case "success":
            return "hasValue";
        default:
            return "loading";
    }
}

/**
 * A handy function to convert the date to ISO string
 * @param {Date} date: the date to be converted
 * @returns
 */
export function toISOString(date: string) {
    return new Date(date).toISOString();
}

export default function mapJoiErrorToValidationState(JoiError: ValidationError) {
    const errors = Array.isArray(JoiError.details) ? JoiError.details : [JoiError.details];
    return Object.fromEntries(errors.map((error) => [error.context?.key || "", error.message]));
}

export function deepCombine(obj1: unknown, obj2: unknown) {
    const obj1Type = typeof obj1;
    const obj2Type = typeof obj2;
    if (obj1Type !== "object") {
        if (obj1Type === obj2Type) return obj2;
        return obj1;
    }

    if (obj2Type !== "object") {
        return obj1;
    }

    const obj2Keys = Object.keys(obj2 as Record<string, unknown>);

    const combined = { ...(obj1 as Record<string, unknown>) };

    const object1 = obj1 as Record<string, unknown>;
    const object2 = obj2 as Record<string, unknown>;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < obj2Keys.length; i++) {
        const key = obj2Keys[i];
        if (key in object1) {
            combined[key] = deepCombine(object1[key], object2[key]);
        } else {
            combined[key] = object2[key];
        }
    }

    return combined;
}

export function combineEmotionStyles(...styles: any[]) {
    if (styles.length === 0) return {};
    return (theme: Theme) =>
        styles.reduce(
            (acc, style) => deepCombine(acc, typeof style === "function" ? style(theme) : style),
            {}
        );
}

/**
 * Creates a function that checks if any items passed in are equal to the value
 */
export function orMatchFunction<TValue>(value: TValue) {
    return (...items: TValue[]) => items.some((item) => item === value);
}

export type OrMatchFunction<TValue> = (...items: TValue[]) => boolean;

export function checkIfPermitted(
    permissions: PermissionModel[] | number[],
    routePermissionId: number | number[]
) {
    if (permissions.length === 0) return false;
    const userPermissions =
        typeof permissions[0] === "number"
            ? (permissions as number[])
            : (permissions as PermissionModel[]).map((permission) => permission.id as number);
    if (Array.isArray(routePermissionId)) {
        return routePermissionId.some((id) => userPermissions.includes(id));
    }

    return userPermissions.includes(routePermissionId);
}

export type MappedRecord<TModel, MappedType> = { [key in keyof TModel]: MappedType };

export type MappedArr<T extends readonly any[], TFrom = any, TTo = unknown> = {
    [K in keyof T]: T[K] extends TFrom ? TTo : never;
};

export const formatDayjsToUTC = (time: Dayjs) => time.format("YYYY-MM-DDTHH:mm:ss.SSS");

export function formatChartData<TData extends Record<PropertyKey, unknown>>(
    chartData?: TData,
    t?: TFunction<"reporting">
) {
    return Object.entries(chartData || {}).map(([key, value]) => ({
        label: t?.(key) || "",
        data: value,
    }));
}

export type QueryOptions<TQueryKey extends QueryKey> = QueryFunctionContext<TQueryKey>;

declare module "@tanstack/react-table" {
    interface TableMeta<TData> {
        [key: string]: unknown;
        t?: TFunction;
    }
}

export function getTFromMeta<TData, TValue>(
    ctx: HeaderContext<TData, TValue> | CellContext<TData, TValue>
): TFunction {
    const fallback = (key: string) => key;
    return ctx.table.options.meta?.t || (fallback as TFunction);
}

export function clamp(min: number, value: number, max: number) {
    return Math.min(Math.max(min, value), max);
}

export function capitalizeFirst(str?: string) {
    if (!str) return undefined;
    return str.charAt(0).toUpperCase() + str.slice(1);
}
